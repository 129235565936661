<template>
  <v-app class="mx-auto">
    <v-app-bar>
      <v-row no-gutters class="align-center">
        <v-icon
          icon="$menu"
          class="v-col-2 d-lg-none d-flex justify-center"
          color="primary"
          size="large"
          aria-label="menu"
          @click.stop="drawer = !drawer"
        ></v-icon>

        <v-col cols="6" md="7" lg="3" xl="2" class="d-flex justify-center">
          <ImageLogo />
        </v-col>

        <v-col cols="7" md="7" lg="7" xl="9" class="d-none d-lg-block">
          <Menu v-if="page?.menu" :menu="page.menu" class="d-flex" :nav="true" width="100%" />
        </v-col>

        <v-col cols="4" md="3" lg="2" xl="1" class="d-flex justify-center">
          <Button :download-nick="nick" :software-title="soft" :text="page.downloadTitle" />
        </v-col>
      </v-row>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" temporary location="top">
      <Menu v-if="page?.menu" :menu="page.menu" class="mt-4" />
    </v-navigation-drawer>

    <v-main>
      <breadcrumbs
        v-if="page.breadcrumbs && page.breadcrumbs.length > 0"
        :items="page.breadcrumbs"
        devider-icon="$chevronRight"
        devider-color="breadcrumbs"
        class="py-0 mt-2 ma-0"
      />
      <aside v-if="page?.blocks?.AboveContent" class="my-1">
        <Region :key="page.uri" region="AboveContent" :blocks="page.blocks.AboveContent" />
      </aside>

      <v-row v-if="page?.blocks?.RightSide" no-gutters>
        <v-col cols="12" md="9">
          <article class="mr-2 mt-2 pa-5">
            <Article :key="page.uri" class="ma-2 pa-3"></Article>

            <Button
              :download-nick="nick"
              :software-title="soft"
              :text="page.downloadTitle"
              class="d-block ma-auto pa-2 mt-2"
            />
          </article>
          <aside v-if="page?.blocks?.BelowContent" class="mr-2 mt-2 pa-5">
            <Region :key="page.uri" region="BelowContent" :blocks="page.blocks.BelowContent" />
          </aside>
        </v-col>
        <v-col cols="12" md="3" class="mt-2">
          <aside v-if="page?.blocks?.RightSide">
            <Region
              :key="page.uri"
              region="RightSide"
              :blocks="page.blocks.RightSide"
              class="mb-2"
              @noregion="page.hideRegion"
            />
          </aside>
        </v-col>
      </v-row>
      <template v-else>
        <article class="pa-5">
          <Article :key="page.uri" class="ma-2 pa-3"></Article>
          <Button
            :download-nick="nick"
            :software-title="soft"
            :text="page.downloadTitle"
            class="d-block ma-auto pa-2 mt-2"
          />
        </article>
        <aside v-if="page?.blocks?.BelowContent" class="blockDesign">
          <Region :key="page.uri" region="BelowContent" :blocks="page.blocks.BelowContent" />
        </aside>
      </template>
      <footer v-if="page?.blocks?.Footer" class="d-block">
        <Region :key="page.uri" region="Footer" :blocks="page.blocks.Footer" />
      </footer>
    </v-main>
  </v-app>
</template>
<script setup>
const page = inject("page");
const nick = ref(unref(page)?.rels?.downloadNick);
const soft = ref(unref(page)?.rels?.softwareTitle);
const drawer = ref(false);

watchEffect(() => {
  nick.value = unref(page)?.rels?.downloadNick;
  soft.value = unref(page)?.rels?.softwareTitle;
});
</script>
